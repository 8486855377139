import React from "react";
import { BrowserRouter } from "react-router-dom";
import Homepage from "./pages/Homepage";
import MetaTags from "react-meta-tags";
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <MetaTags>
        <meta property="og:title" content="UpDavo CV" />
        <meta property="og:image" content="/profile.jpg" />
        <meta
          property="og:description"
          content="Soy un Profesional en Ingeniería en Software, con amplias habilidades matemáticas, diseño de interfases basadas en la experiencia del usuario, redes y conocimientos en el área de programación web/móvil"
        />
        <meta
          property="og:url"
          content="https://curriculum-updavo.vercel.app/"
        />
      </MetaTags>
      <Homepage />
    </BrowserRouter>
  );
}

export default App;