import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

//Constante para la informacción inicial
const aboutContent = {
  name: "Anthony Villegas",
  avatarImage: "/profile.jpg",
  content: `¡Hola! Soy un Profesional en Ingeniería en Software, con amplias habilidades en el área de programación web como: Ecosistema Javascript (Angular, Node.js, Firebase, Amplify, React), CSS (Bootstrap, Tailwind), PHP Básico, Flutter y Python; Además de tener conocimientos adicionales que complementan el desarrollo web como: SEO, CRM, Facebook Ads Manager, Email marketing y Pixeles.`,
};

//Constante para las barras de progreso
const progressData = [
  {
    id: 2,
    title: "UX/UI",
    percantage: 75,
    progressColor: "#F40543",
  },
  {
    id: 3,
    title: "Javascript/Frameworks (Front - Back)",
    percantage: 95,
    progressColor: "#e5b96c",
  },
  {
    id: 5,
    title: "Python (Analíticas de Datos)",
    percantage: 70,
    progressColor: "#6ce5b7",
  },
  {
    id: 6,
    title: "Adobe Suite",
    percantage: 88,
    progressColor: "#986ce5",
  },
  {
    id: 7,
    title: "Flutter",
    percantage: 50,
    progressColor: "#e5866c",
  },
];

//Constante para el contador
const counterData = [
  {
    id: 1,
    title: "Proyectos Completados",
    count: 40,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Tazas de Café",
    count: 7230,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Clientes Satisfechos",
    count: 1200,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Participación en Hackatones",
    count: 5,
    icon: "icon-badge",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="Acerca de mi" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img
                src={aboutContent.avatarImage}
                alt={aboutContent.name}
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p style={{ textAlign: "justify", fontSize: "19px" }}>
                    {aboutContent.content}
                  </p>
                  <div className="mt-4">
                    <a
                      href="https://drive.google.com/file/d/1zCT0FJnJ6akeSfvV5IxgaUCI1obfsNQ4/view?usp=share_link"
                      className="btn btn-default2 btn-full"
                    >
                      Descargar CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
